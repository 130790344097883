export const getDefaultValues = (onlyDefault = false) => {
  const defaultValues = {
    doorStepResidential: 0,
    doorStepCommercial: 0,
    doorStepDropOut: 0,
    doorStepClosedGroup: 0,
    gateCollectionPaymentGroup: "Cash",
  };

  if (onlyDefault) {
    return defaultValues;
  }
  return localStorage.getItem("userInputData")
    ? JSON.parse(localStorage.getItem("userInputData"))
    : defaultValues;
};

export const handleBack = (current, rememberMe, data) => {
  let back = current - 1;

  if (back === 7) {
    if (
      data["wasteCollectionServiceGroup"] === "Unallocated" ||
      data["wasteCollectionServiceGroup"] === "Allocated But Unserviced"
    ) {
      back = handleBack(back, rememberMe, data);
    }
  }
  return back;
};

export const handleNext = (current, rememberMe, data) => {
  let next = current + 1;

  if (next === 7) {
    if (
      data["wasteCollectionServiceGroup"] === "Unallocated" ||
      data["wasteCollectionServiceGroup"] === "Allocated But Unserviced"
    ) {
      next = handleNext(next, rememberMe, data);
    }
  }
  return next;
};

export const calculateNext = (current, rememberMe, data) => {
  let next = current + 1;
  if (next === 0 && rememberMe["mapSlumGroup"]) {
    next = calculateNext(next, rememberMe, data);
  }
  if (next === 1 && rememberMe["subWardGroup"]) {
    next = calculateNext(next, rememberMe, data);
  }
  if (next === 2 && rememberMe["slumNameGroup"]) {
    next = calculateNext(next, rememberMe, data);
  }
  if (next === 3 && rememberMe["recordGroup"]) {
    next = calculateNext(next, rememberMe, data);
  }
  if (next === 4 && rememberMe["shiftGroup"]) {
    next = calculateNext(next, rememberMe, data);
  }

  if (next === 7) {
    if (data["wasteCollectionServiceGroup"] === "Unallocated") {
      next = calculateNext(next, rememberMe, data);
    }
  }
  return next;
};
