import React from "react";
import { useFetch } from "./useFetch";

export const SubWardList = ({ data, handleChange }) => {
  const subWardList = useFetch(
    `${process.env.REACT_APP_API_BASE_URI}/api/Users/fetchAllSubWards`
  );

  return (
    <div className="form-group form-button" id="subWardGroup">
      <div>
        {subWardList?.map((field) => (
          <React.Fragment key={field.sub_ward}>
            <input
              id={field.sub_ward}
              type="radio"
              name="subWardGroup"
              className="radioOption"
              value={field.sub_ward}
              checked={data["subWardGroup"] === field.sub_ward}
              onChange={(e) => {
                handleChange("subWardGroup")(e.target.value);
              }} />
            <label htmlFor={field.sub_ward} className="button button-solid">
              {field.sub_ward}
            </label>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
