import React from "react";

export const GateCollectionContactDetails = ({ data, handleChange }) => {
  return <>
    <div className="form-group" id="gateCollectionNameGroup">
      <input
        type="text"
        placeholder="Type name"
        id="gateCollectionName"
        value={data["gateCollectionName"]}
        required
        onChange={(e) => {
          handleChange("gateCollectionName")(e.target.value);
        }} />
    </div>

    <div className="form-group" id="gateCollectionNumberGroup">
      <input
        type="number"
        placeholder="Type Contact number (Optional)"
        id="gateCollectionContactNumber"
        value={data["gateCollectionContactNumber"]}
        onChange={(e) => {
          if (e.target.value?.length <= 10) {
            handleChange("gateCollectionContactNumber")(e.target.value);
          }
        }} />
    </div>

    <div
      className="form-group form-button"
      id="gateCollectionTitleGroup"
    >
      <div>
        <h3>Contact Type</h3>
        {["Property Owner", "Tenant"].map((field) => (
          <React.Fragment key={field}>
            <input
              id={field}
              type="radio"
              name="gateCollectionTitleGroup"
              className="radioOption"
              value={field}
              checked={data["gateCollectionTitleGroup"] === field}
              onChange={(e) => {
                handleChange("gateCollectionTitleGroup")(
                  e.target.value
                );
              }} />
            <label htmlFor={field} className="button button-solid">
              {field}
            </label>
          </React.Fragment>
        ))}
      </div>
    </div>
  </>;
};
