import React from "react";
import { ReactComponent as PlusIcon } from "./image/pluse-icon.svg";

export const UploadFileWasteSelection = ({ data, handleChange }) => {
  return (
    <>
      <div className="form-group upload-file">
        <div className="upload-file-edit">
          <input
            type="file"
            id="fileInput"
            accept="image/*"
            capture
            onChange={(e) => {
              if (e.target.files && e.target.files[0]) {
                var reader = new FileReader();

                reader.onload = function (e) {
                  handleChange("imagePreview")(e.target.result);
                };

                reader.readAsDataURL(e.target.files[0]);
              }
            }}
          />
          <PlusIcon className="upload-file-preview-pluse" alt="" />
          <label htmlFor="fileInput">Upload photo</label>
          <img
            className="upload-file-preview-image"
            id="imagePreview"
            src={data["imagePreview"] || require("./image/profile.jpeg")}
            alt=""
            style={data["imagePreview"] ? {} : { display: "none" }}
          />
        </div>
      </div>
      <div className="form-group form-button" id="wasteCollectionServiceGroup">
        <div>
          <h3>Waste collection service</h3>
          {[
            { label: "Doorstep Service", note: "सर्विस चालू आहे" },
            { label: "Gully/Corner Collection", note: "सर्विस चालू आहे" },
            {
              label: "Allocated But Unserviced",
              note: "मेम्बर ला दिले पन जात नाही",
            },
            { label: "Unallocated", note: "कोणालाच दिलेले नाही" },
          ].map((field) => (
            <React.Fragment key={field.label}>
              <input
                id={field.label}
                type="radio"
                name="wasteCollectionServiceGroup"
                className="radioOption"
                value={field.label}
                checked={data["wasteCollectionServiceGroup"] === field.label}
                onChange={(e) => {
                  handleChange("wasteCollectionServiceGroup")(e.target.value);
                }}
                disabled={!data["imagePreview"]}
              />
              <label
                htmlFor={field.label}
                className="button button-solid"
                style={{ flexDirection: "column" }}
              >
                {field.label}
                <span style={{ fontSize: "0.8rem" }}>{field.note}</span>
              </label>
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};
