import { ReactComponent as Icon } from "./image/refresh-icon.svg";
import { ReactComponent as LeftIcon } from "./image/left-circle-svgrepo-com.svg";
import { ReactComponent as RightIcon } from "./image/right-circle-svgrepo-com.svg";
import React from "react";
import { useAuth } from "./AuthProvider";
import { useNavigate } from "react-router";
import { RememberMe } from "./RememberMe";

export const Layout = ({
  id,
  children,
  hideHeader = false,
  onBack,
  onNext,
  disabledBack,
  disabledNext,
  onRest,
  rememberMe,
  setRememberMe,
  title,
  renderSubHeader,
  resetClassName = "icon-refresh-below-header",
}) => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  return (
    <div className="container">
      {onBack && (
        <LeftIcon
          onClick={() => !disabledBack && onBack()}
          style={{
            position: "sticky",
            top: "80%",
            fontSize: "3rem",
            left: 0,
            zIndex: 999,
            ...(disabledBack ? { opacity: 0.2 } : {}),
          }}
        />
      )}
      {onNext && (
        <RightIcon
          onClick={() => !disabledNext && onNext()}
          style={{
            position: "sticky",
            top: "80%",
            fontSize: "3rem",
            left: "80%",
            zIndex: 999,
            ...(disabledNext ? { opacity: 0.2 } : {}),
          }}
        />
      )}
      <div id={!hideHeader && "dynamicForm"}>
        {!hideHeader && (
          <div className="header">
            <div className="logo">
              <img src={require("./image/logo.png")} alt="logo" />
            </div>
            <div className="profile">
              <div
                onClick={() => {
                  logout();
                  navigate("/login");
                }}
              >
                <img
                  src={
                    !localStorage.getItem("photo_link") ||
                    localStorage.getItem("photo_link") === "null" ||
                    localStorage.getItem("photo_link") === "undefined"
                      ? require("./image/profile.jpeg")
                      : localStorage.getItem("photo_link")
                  }
                  alt="profile"
                />
              </div>
            </div>
          </div>
        )}
        <div id={id}>
          <div>
            {renderSubHeader}
            {onRest && (
              <Icon
                onClick={onRest}
                className={`icon-refresh ${resetClassName}`}
              />
            )}
            {title && <h3>{title}</h3>}
            {setRememberMe && (
              <RememberMe
                fieldName={id}
                rememberMe={rememberMe}
                setRememberMe={setRememberMe}
              />
            )}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};
