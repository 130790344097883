import { useState } from "react";
import { ReactComponent as SearchIcon } from "./image/search-icon.svg";
import React from "react";
import { usePost } from "./Login";
import { Layout } from "./Layout";
import { UserList } from "./UserList";
import { SlumList } from "./SlumList";
import { SubWardList } from "./SubWardList";
import { SlumSelection } from "./SlumSelection";
import { ShiftSelection } from "./ShiftSelection";
import { UploadFileWasteSelection } from "./UploadFileWasteSelection";
import { DoorStepCollection } from "./DoorStepCollection";
import { GateCollection } from "./GateCollection";
import { GateCollectionContactDetails } from "./GateCollectionContactDetails";
import { calculateNext, getDefaultValues } from "./utils";
import { RememberMe } from "./RememberMe";
import { useData, useFindGeoCode, useIndex, useRememberMe } from "./hooks";

const Dashboard = () => {
  const [searchTxt, setSearchTxt] = useState("");

  const geoCode = useFindGeoCode();
  const { rememberMe, setRememberMe, onRestRememberMe } = useRememberMe();
  const { data, onRestData, setData, handleChange } = useData(rememberMe);
  const { index, setIndex, onNext, onBack, onIndexRest } = useIndex(
    rememberMe,
    data
  );

  const onRest = () => {
    onRestData(true);
    onRestRememberMe();
    onIndexRest();
  };

  const { error, isLoading, makeRequest, makeRequestRest } = usePost(
    `${process.env.REACT_APP_API_BASE_URI}/api/Users/saveProperty`,
    (res) => {
      if (res.success) {
        alert("Property Mapped Successfully.");
        const savedData = getDefaultValues();

        const savedRememberMe = localStorage.getItem("rememberMe")
          ? JSON.parse(localStorage.getItem("rememberMe"))
          : {};
        setData(savedData);
        setRememberMe(savedRememberMe);
        setIndex(calculateNext(-1, savedRememberMe, savedData));
      } else {
        makeRequestRest();
      }
    }
  );

  if (!geoCode) {
    return;
  }

  const layoutProps = {
    0: {
      onNext,
      onRest,
      hideHeader: true,
      resetClassName: "icon-refresh-header",
      disabledNext: !data["mapSlumGroup"],
      children: (
        <div id="sign-up-form">
          <img src={require("./image/logo.png")} alt="Poornachakra Cart" />
          <div id="mapSlumWarpper">
            <RememberMe
              fieldName="mapSlumGroup"
              rememberMe={rememberMe}
              setRememberMe={setRememberMe}
            />
            <SlumSelection data={data} handleChange={handleChange} />
          </div>
        </div>
      ),
    },
    1: {
      onNext,
      onRest,
      onBack,
      disabledNext: !data["subWardGroup"],
      id: "subWardGroup",
      title: "Select sub-ward",
      rememberMe,
      setRememberMe,
      children: <SubWardList data={data} handleChange={handleChange} />,
    },
    2: {
      onNext,
      onRest,
      onBack,
      disabledNext: !data["slumNameGroup"],
      id: "slumNameGroup",
      title: "Select slum name",
      rememberMe,
      setRememberMe,
      children: <SlumList data={data} handleChange={handleChange} />,
    },
    3: {
      onNext,
      onRest,
      onBack,
      disabledNext: !data["recordGroup"]?.length,
      id: "recordGroup",
      rememberMe,
      setRememberMe,
      resetClassName: "icon-refresh-record-group",
      renderSubHeader: (
        <div className="top-bar">
          <input
            type="text"
            value={searchTxt}
            onChange={(e) => setSearchTxt(e.target.value)}
            placeholder="Search..."
          />
          <SearchIcon />
        </div>
      ),
      children: (
        <UserList
          searchTxt={searchTxt}
          data={data}
          handleChange={handleChange}
        />
      ),
    },
    4: {
      onNext,
      onRest,
      onBack,
      disabledNext: !data["shiftGroup"],
      id: "shiftGroup",
      title: "Select shift",
      rememberMe,
      setRememberMe,
      children: <ShiftSelection data={data} handleChange={handleChange} />,
    },
    5: {
      onNext,
      onRest,
      onBack,
      disabledNext: !data["wasteCollectionServiceGroup"],
      id: "wasteCollectionServiceGroup",
      children: (
        <UploadFileWasteSelection data={data} handleChange={handleChange} />
      ),
    },
    6: {
      onNext,
      onRest,
      onBack,
      disabledNext: !(
        +data["doorStepResidential"] + +data["doorStepCommercial"]
      ),

      id: "doorStepCollectionwrapper",
      children: <DoorStepCollection data={data} handleChange={handleChange} />,
    },
    7: {
      onNext,
      onRest,
      onBack,
      disabledNext: !data["gateCollectionFeedbackGroup"],
      id: "gateCollectionPaymentwrapper",
      children: <GateCollection data={data} handleChange={handleChange} />,
    },
    8: {
      onBack,
      title: "Contact details",
      onRest,
      children: (
        <>
          <GateCollectionContactDetails
            data={data}
            handleChange={handleChange}
          />
          <button
            id="submit"
            className="submit-btn"
            disabled={
              isLoading ||
              error ||
              !data["gateCollectionName"] ||
              (data["gateCollectionContactNumber"] &&
                data["gateCollectionContactNumber"]?.length !== 10)
            }
            onClick={() => {
              makeRequest({
                ...data,
                ...geoCode,
                recordGroup: data.recordGroup?.map((x) => x.id).join(","),
              });
            }}
          >
            Submit
          </button>
        </>
      ),
    },
  };

  return <Layout {...layoutProps[index]} />;
};

export default Dashboard;
