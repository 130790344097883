import { useEffect, useState } from "react";
import { calculateNext, getDefaultValues, handleBack, handleNext } from "./utils";

export const useFindGeoCode = () => {
  const [geoCode, setGeoCode] = useState();

  useEffect(() => {
    if (!navigator.geolocation) {
      alert("Geo Location sharing is not supported on your device.");
    }
    navigator.permissions.query({ name: "geolocation" }).then((stat) => {
      if (stat.state !== "granted") {
        alert("Please grant access to your geo location and reload the page.");
      }
    });
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        const crd = pos.coords;
        setGeoCode({
          latitude: crd.latitude,
          longitude: crd.longitude,
        });
      },
      console.log,
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    );
  }, []);

  return geoCode;
};

export const useIndex = (rememberMe, data) => {
  const [index, setIndex] = useState(calculateNext(-1, rememberMe, data));

  const onNext = () => setIndex(handleNext(index, rememberMe, data));
  const onBack = () => setIndex(handleBack(index, rememberMe, data));
  const onIndexRest = () => setIndex(handleNext(-1, {}, {}));

  return {
    index,
    setIndex,
    onNext,
    onBack,
    onIndexRest,
  };
};

export const useRememberMe = () => {
  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem("rememberMe")
      ? JSON.parse(localStorage.getItem("rememberMe"))
      : {}
  );

  const onRestRememberMe = () => {
    setRememberMe({});
    localStorage.removeItem("rememberMe");
    localStorage.removeItem("userInputData");
  };

  return {
    rememberMe,
    setRememberMe,
    onRestRememberMe,
  };
};

export const useData = (rememberMe) => {
  const [data, setData] = useState(getDefaultValues());

  const onRestData = (onlyDefault = false) =>
    setData(getDefaultValues(onlyDefault));

  const handleChange = (fieldName) => (value) => {
    if (rememberMe[fieldName]) {
      const oldData = getDefaultValues();
      localStorage.setItem(
        "userInputData",
        JSON.stringify({
          ...oldData,
          [fieldName]: value,
        })
      );
    }

    setData({
      ...data,
      [fieldName]: value,
    });
  };

  return {
    data,
    setData,
    onRestData,
    handleChange,
  };
};
