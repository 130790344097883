import { useState, useEffect } from "react";

export const useFetch = (url) => {
  const [responseData, setResponseData] = useState();

  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((data) => setResponseData(data.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return responseData;
};
