import React from "react";

export const RememberMe = ({ fieldName, rememberMe, setRememberMe }) => (
  <div className="form-group remember-me">
    <input
      type="checkbox"
      id={fieldName}
      value={fieldName}
      checked={rememberMe[fieldName]}
      onChange={(e) => {
        localStorage.setItem(
          "rememberMe",
          JSON.stringify({
            ...rememberMe,
            [fieldName]: e.target.checked,
          })
        );
        setRememberMe({
          ...rememberMe,
          [fieldName]: e.target.checked,
        });
      }} />
    <label htmlFor={fieldName}>Remember Choice</label>
  </div>
);
