import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { useAuth } from "./AuthProvider";
import { ReactComponent as Icon } from "./image/refresh-icon.svg";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";

export const usePost = (url, onSuccess) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const makeRequestRest = useCallback(() => {
    setIsLoading(false);
    setError(null);
    setData(null);
  }, []);

  const makeRequest = useCallback(
    async (requestData) => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.post(url, requestData);
        if (response.data?.success) {
          setData(response.data);
          onSuccess?.(response.data);
        } else {
          if (response.message) {
            alert(response.message);
          }
        }
      } catch (err) {
        setError(err);
      }
      setIsLoading(false);
    },
    [onSuccess, url]
  );

  return { makeRequest, data, isLoading, error, makeRequestRest };
};

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const { state } = useLocation();
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");

  const { makeRequest, data, isLoading } = usePost(
    `${process.env.REACT_APP_API_BASE_URI}/api/Users/validateUserByMobile`
  );

  const { makeRequest: validateOtp } = usePost(
    `${process.env.REACT_APP_API_BASE_URI}/api/Users/validateUserByOtp`,
    (res) => {
      if (res.success) {
        login().then(() => {
          localStorage.setItem("photo_link", data.photo_link);
          localStorage.setItem("user_id", data.employee_id);
          navigate(state?.path || "/");
        });
      }
    }
  );

  useEffect(() => {
    localStorage.setItem("Swach_Logged_In", true);
  }, []);

  const handleLogin = () => {
    validateOtp({
      id: data?.employee_id,
      otp,
    });
  };

  return (
    <div class="container">
      <div id="sign-up-form">
        <Icon class="icon-refresh icon-refresh-header" />
        <img src={require("./image/logo.png")} alt="Poornachakra Cart" />
        <div class="sign-up-form">
          <div class="form-group">
            <input
              type="number"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              placeholder="Mobile No."
            />
          </div>
          {data ? (
            <>
              <div class="form-group">
                <input
                  type="password"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="OTP"
                />
              </div>

              <div class="form-group remember-me">
                <input
                  type="checkbox"
                  id="rememberMe"
                  defaultChecked={true}
                  onChange={(e) => {
                    localStorage.setItem("Swach_Logged_In", e.target.checked);
                  }}
                />
                <label for="rememberMe">Remember Me</label>
              </div>
              <button
                id="submit-btn"
                onClick={handleLogin}
                disabled={!otp}
                class="submit-btn"
              >
                Submit
              </button>
            </>
          ) : (
            <div class="form-group">
              <button
                id="submit-btn"
                onClick={() => {
                  makeRequest({ mobile });
                }}
                class="submit-btn"
                disabled={isLoading}
              >
                Request Otp
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
